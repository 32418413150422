"use client"
import styles from './gallery.module.scss'
import { useEffect, useState } from 'react';
import elements from './gallery.json';

function Carousel() {
  const [prev, setPrev] = useState(0);
  const [active, setActive] = useState(1);
  const [next, setNext] = useState(2);

  let forward = () => {
    setPrev(active);
    setActive(next);
    setNext(next === elements.length - 1 ? 0 : next + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      forward()
    }, 6000);

    return () => clearInterval(interval);
  }, [forward]);

  return (
    <>
      <div className={styles.carousel}>
        <div className={styles.gallery}>
          {elements.map(element => {
            if (prev === element[0]) {
              return (<>
                <div key={element[0]} className={[styles.itemprev, styles.item].join(" ")}>
                  <img className={styles.image} src={element[1].toString()} alt="" />
                </div>
              </>)
            }
            if (active === element[0]) {
              return (<>
                <div key={element[0]} className={[styles.itemactive, styles.item].join(" ")}>
                  <img className={styles.image} src={element[1].toString()} alt="" />
                </div>
              </>)
            }
            if (next === element[0]) {
              return (<>
                <div key={element[0]} className={[styles.itemnext, styles.item].join(" ")}>
                  <img className={styles.image} src={element[1].toString()} alt="" />
                </div>
              </>)
            }
            return (<>
              <div key={element[0]} className={[styles.item].join(" ")}>
                <img className={styles.image} src={element[1].toString()} alt="" />
              </div>
            </>)
          })}
        </div>
      </div>
    </>
  );
}

export default Carousel;