import './gallery.scss'
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import {  motion } from 'framer-motion';

type JobProps = {
  name: string
}

function Job(props: JobProps) {
  const FIRST_IMAGE = {
    imageUrl: './gallery/'+props.name+"/2.webp",
    alt: './gallery/'+props.name+"/2.webp"
  };
  const SECOND_IMAGE = {
    imageUrl: './gallery/'+props.name+"/1.webp",
    alt: './gallery/'+props.name+"/1.webp"
  };

  const delimiterIconStyles = {
    width: '50px',
    height: '50px',
    backgroundSize: '50%',
    backgroundRepeat: "no-repeat", 
    borderRadius: 'none',
    backgroundImage: 'url(./arrow.webp)',
    backgroundPosition: "center"
 }


  return(<>
    <motion.div
      initial={{ opacity: 0, scale: 0.98 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 1.2, delay: 0.3*1 }}         
      className="job">
      <ReactBeforeSliderComponent
        firstImage={FIRST_IMAGE}
        secondImage={SECOND_IMAGE}
        delimiterIconStyles={delimiterIconStyles}
      />
    </motion.div>
  </>);
}

export default Job;