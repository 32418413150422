import './App.scss';
import Carousel from './Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { motion } from 'framer-motion';
import Gallery from './gallery/Gallery';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCaL2CNfhQ9oV3fMF5qWbAdRBNp0Rigv9o",
  authDomain: "optimodo.firebaseapp.com",
  projectId: "optimodo",
  storageBucket: "optimodo.appspot.com",
  messagingSenderId: "46445490245",
  appId: "1:46445490245:web:2deb8d5a45cf366c14b32c",
  measurementId: "G-PY0T2F9Q9M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let goto_about = () => {
  window.scrollTo({
    top: document.querySelector<HTMLElement>(".about")?.offsetTop! - 160,
    behavior: "smooth"
  });
  logEvent(analytics, 'goto_about')
}

let goto_contact = () => {
  window.scrollTo({
    top: document.querySelector<HTMLElement>(".contact")?.offsetTop! - 100,
    behavior: "smooth"
  });
  logEvent(analytics, 'goto_contact')
}

let goto_gallery = () => {
  window.scrollTo({
    top: document.querySelector<HTMLElement>(".gallery")?.offsetTop! - 100,
    behavior: "smooth"
  });
  logEvent(analytics, 'goto_gallery')
}

let goto_services = () => {
  window.scrollTo({
    top: document.querySelector<HTMLElement>(".services")?.offsetTop! - 120,
    behavior: "smooth"
  });
  logEvent(analytics, 'goto_services')
}

function App() {
  return (
    <>
      <div className='main'>
        <input onClick={()=> {
          logEvent(analytics, 'open_nav_mobile')
        }} className="checkbox" type="checkbox" name="" id="" />
        <div className='menup'>Menu</div>
        <div className="hamburgerLines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div>
        <div className="bg"></div>
        <div
          className='nav'>
          <motion.div 
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.2 }}
            className="elements">
            <div className="logo">
              <img src="./optimodo3.webp" alt="" />
            </div>
            <div className="links">
              <ul>
                <li><a onClick={(e) => {
                  goto_about()
                }}><p><FontAwesomeIcon icon={icon({name: 'user', style: 'solid'})} /> About us</p></a></li>
                <li><a onClick={(e) => {
                  goto_services()
                }}><p><FontAwesomeIcon icon={icon({name: 'gear', style: 'solid'})} /> Our services</p></a></li>
                <li><a onClick={(e) => {
                  goto_contact()
                }}><p><FontAwesomeIcon icon={icon({name: 'envelope', style: 'solid'})} /> Contact us</p></a></li>
                <li><a onClick={(e) => {
                  goto_gallery()
                }}><p><FontAwesomeIcon icon={icon({name: 'image', style: 'solid'})} /> Gallery</p></a></li>
              </ul>
            </div>
          </motion.div>
        </div>
        <div className="bg1">
          <Carousel/>
            <div className="custom-shape-divider-bottom-1697467613">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
              </svg>
            </div>
        </div>
        <div className='landing'>
          <div className="content">
            <div className="about">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 1.2, delay: 0.3 }}
                className="left">
                <h1>About Opti<span className="contrast1">m</span><span className="contrast2">o</span>do</h1>
                {/* <p>Optimodo Limited was established in 2009 and is based in Hemel Hempstead operating in Hertfordshire and the surrounding areas. </p> */}
                <p>Optimodo Limited was established in 2009 and is based in Hemel Hempstead operating in Hertfordshire and the surrounding areas.</p>
                <p>We consistently deliver work with care and attention to detail that every customer deserves.</p>
                {/* <p>We offer a comprehensive, honest and reliable building service and are committed to providing high-quality delivery and excellent workmanship.</p> */}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 1.2, delay: 0.3 }}
                className="right">
                <img src="./info.svg" alt="" />
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <div className="scrollsection">
        <div onClick={(e) => {
          goto_services()
        }} className="wrapper">
          <div className="scroll-top">
            <FontAwesomeIcon icon={solid("chevron-down")} />
          </div>
          <div className="scroll-bot">
            <FontAwesomeIcon icon={solid("chevron-down")} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="services">
          <div className="services2">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 1.2, delay: 0.3 }}
              className="left">
              <img src="./terms.svg" alt="" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 1.2, delay: 0.3 }} 
              className="right">
              <h1>Our ser<span className="contrast1">v</span><span className="contrast2">i</span>ces</h1>
              <p>We specialise in:</p>
              <ul>
                <li><img className='nobg' src="./no-bg.webp" alt="./no-bg.webp" /> General refurbishments</li>
                <li><img className='nobg' src="./no-bg.webp" alt="./no-bg.webp" /> Carpentry</li>
                <li><img className='nobg' src="./no-bg.webp" alt="./no-bg.webp" /> Kitchens</li>
                <li><img className='nobg' src="./no-bg.webp" alt="./no-bg.webp" /> Bathrooms and tiling</li>
              </ul>
              <p>We also cover works such as:</p>
              <ul className='secondary'>
                <li><img className='smaller' src="./orng.webp" alt="./orng.webp" /> Porches</li>
                <li><img className='smaller' src="./blue.webp" alt="./blue.webp" /> Extensions</li>
                <li><img className='smaller' src="./orng.webp" alt="./orng.webp" /> New builds</li>
                <li><img className='smaller' src="./blue.webp" alt="./blue.webp" /> Garage conversions</li>
                <li><img className='smaller' src="./orng.webp" alt="./orng.webp" /> Loft conversions</li>
                <li><img className='smaller' src="./blue.webp" alt="./blue.webp" /> Driveways, patios and fences</li>
              </ul>
            </motion.div>
          </div>
        </div>
        <div className="scrollsection">
        <div onClick={(e) => {
          goto_contact()
        }} className="wrapper">
          <div className="scroll-top">
            <FontAwesomeIcon icon={solid("chevron-down")} />
          </div>
          <div className="scroll-bot">
            <FontAwesomeIcon icon={solid("chevron-down")} />
          </div>
        </div>
      </div>
        {/* <div className="parallax-image sticky" style={{backgroundImage: "url('./bgabs2.webp')"}}></div> */}
        <div className="contact">
          <div className='bg1'>
            <div className="custom-shape-divider-top-1698073894">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
              </svg>
            </div>
            <div className="div" style={{backgroundImage: "url('./bgabs2.webp')"}}></div>
            <div className="custom-shape-divider-bottom-1697467613">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
              </svg>
            </div>
          </div>
          <div className="contact2">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 1.2, delay: 0.3 }}
              className="left">
                <h1>
                  How to con<span className="contrast1">t</span><span className="contrast2">a</span>ct us?
                </h1>
                <ul>
                  <li><FontAwesomeIcon icon={solid("user")} /> Steve Golc</li>
                  <li><FontAwesomeIcon icon={solid("mobile-screen")} /> 07944 274 122</li>
                  {/* <li><FontAwesomeIcon icon={solid("phone")} /> 01442 211 767</li> */}
                  <li><FontAwesomeIcon icon={solid("envelope")} /> customer@optimodo.co.uk</li>
                  {/* <li><FontAwesomeIcon icon={solid("location-dot")} /> <span className='address'>Optimodo Limited. 12 Quinces Croft, Hemel Hempstead, HP1 3JT</span></li> */}
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 1.2, delay: 0.3 }}
                className="right">
                <img src="./contact.svg" alt="" />
              </motion.div>
          </div>
        </div>
        <div className="scrollsection">
        <div onClick={(e) => {
          goto_gallery()
        }} className="wrapper">
          <div className="scroll-top">
            <FontAwesomeIcon icon={solid("chevron-down")} />
          </div>
          <div className="scroll-bot">
            <FontAwesomeIcon icon={solid("chevron-down")} />
          </div>
        </div>
      </div>
        <motion.div
          className="gallery">
          <div className="box">
            <Gallery/>
          </div>
        </motion.div>
      </div>

      <div className="stopka">
        <p>Made with ❤️ by <a target='_blank' href="https://thatsnotliam.pl">ThatsNotLiam</a></p>
      </div>
    </>
  );
}

export default App;
