import './gallery.scss';
import Job from './Job';

function Gallery() {
  return (<>
    <div className="content">
      <Job name='1'/>
      <Job name='2'/>
      <Job name='3'/>
      <Job name='4'/>
      <Job name='5'/>
      <Job name='6'/>
      <Job name='7'/>
      <Job name='8'/>
      <Job name='9'/>
      <Job name='10'/>
    </div>
  </>);
}

export default Gallery;
